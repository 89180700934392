.aside {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	transform: translateX(300px);
	overflow-y: scroll;
	background-color: white;
	z-index: 12;
	transition: transform .33s ease-in-out;
	&.active {
		width: 300px;
		transform: translateX(0);
	}
	h5 {
		margin: 1rem 1rem .5rem;
	}
}
.close-info {
	margin: 1rem .5rem 0 0;
}
.obs-wrapper {
	margin: 0 1rem;
}
ul.obs {
	padding-left: 1.5em;
	margin-bottom: .5rem;
	li {
		font-size: 90%;
		margin-bottom: 0;
	}
}
