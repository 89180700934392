$mobile: 50px;
$desktop: 100px;
 
.about-info {
	background-color: white;
	overflow-y: scroll;
	position: absolute;
	top: 50%;
	transform: translateY(-51%);
	max-width: 800px;
	margin: 0 2rem;
	padding: 1rem;
	z-index: 13;
	text-align: center;
	border-radius: 4px;
	opacity: 0;
	visibility: hidden;
	transition: all 1000ms ease-out;
	&.open {
		opacity: 1;
		visibility: visible;
	}
	@media screen and (min-width: 600px) {
		left: 50%;
		transform: translateY(-51%) translateX(-50%);
	}
	code {
		background-color: antiquewhite;
		color: black;
		padding: 0 6px;
	}
	p {
		margin-bottom: .5rem;
	}
}

.close-icon {
	background: none;
	border: 0;
	float: right;
	line-height: 1;
}
