$spacer: 1rem;
$font-family-sans-serif: 'Poppins', sans-serif;
$success: SeaGreen;
$link-color: MediumSeaGreen; 

// @import "variables-darkly";
@import "~bootstrap/scss/bootstrap";
// @import "bootswatch";
@import "aside";
@import "about";

.navbar {
	> .container-fluid {
		justify-content: flex-start;
		align-items: baseline;
	}
	.nav-link {
		color: MediumSeaGreen;
		margin-right: $spacer;
	}
	form.input-group {
		width: auto;
		align-self: stretch;
		margin-left: auto;
	}
}

main {
	flex: 1;
	display: flex;
	align-items: stretch;
	overflow: hidden;
	position: relative;
	width: 100vw;
	background-color: #efe9e1;
}

#map {
	flex-grow: 1;
	background-color: #efe9e1;
	.pink {
		color: pink;
	}
}

.mapboxgl-marker {
	background: hotpink;
	width: 22px;
	height: 22px;
	border: 1.5px solid white;
	border-radius: 50%;
	cursor: pointer;
	@media screen and (min-width: 600px) {
		width: 16px;
		height: 16px;
	}
}

.error-message {
	background-color: white;
	overflow-y: scroll;
	position: absolute;
	top: 50%;
	transform: translateY(-51%);
	max-width: 800px;
	margin: 0 2rem;
	padding: 1rem 2rem;
	z-index: 12;
	text-align: center;
	@media screen and (min-width: 600px) {
		left: 50%;
		transform: translateY(-51%) translateX(-50%);
	}
}

.bird-flying {
	top: 50%;
	left: 50%;
	margin-left: -80px;
	position: absolute;
	z-index: 12;
	margin-top: -100px;
	width: 123px;
	height: 177px;
	mix-blend-mode: multiply;
	transition: opacity 1000ms ease-out, visibility 0ms ease 1000ms;
	&.loaded {
		opacity: 0;
		visibility: hidden;
	}
}
.mapboxgl-ctrl-top-right.location {
	top: 100px;
}

.close-info {
	float: right;
	background: none;
	border: 0;
}

.mapboxgl-marker {
	transition: opacity 500ms ease-out
}

.search-form {
	.btn-success {
		color: white;
		&:hover {
			color: white;
		}
	}
}
